.layout-fields-drawer {
  flex: 0.2;
  margin-right: 1rem;
  overflow: scroll;
  height: 50vh;
  position: sticky;
  top: 0;
}

.layout-fields-drawer div {
  border-radius: 4px;
}
.layout-fields-drawer div:not(:last-child) {
  margin-bottom: 0.2rem;
}

.layout-rows {
  flex: 0.8;
}

.layout-row {
  min-height: 4rem;
  border: 1px solid $catalog-widget-color-3;
  border-radius: 0 0 4px 4px;
}

.layout-column {
  flex: 1;
  margin: 0.5rem;
  border: 1px solid $catalog-widget-color-3;
  min-height: 4rem;
  border-radius: 4px;
}

// UX: when row is empty, we need a space to drop a column
.layout-column-droppable,
.layout-column-sortable {
  min-height: 3rem;
}

.layout-column-sortable div {
  border: 0;
}
.layout-column-sortable div:not(:last-child) {
  margin-bottom: 0.2rem;
}

.layout-column-header {
  min-height: 2rem;
  border: 1px solid $catalog-widget-color-3;
}

.layout-header {
  flex-direction: row;
  display: flex;
  min-height: 2rem;
  justify-content: space-between;
}

div.layout-header.top-border-rad {
  border: 1px solid $catalog-widget-color-3;
  border-radius: 4px 4px 0 0;
}

.layout-builder .ant-btn {
  background-color: transparent;
  border: 0;
  padding: 0.3rem;
}

.layout-builder .ant-btn:hover {
  background-color: $light;
}

.layout-handle {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0.4rem;
}

.layout-row-droppable {
  flex-direction: row;
  flex: 1;
  display: flex;
}

.layout-row-add {
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0.4rem;
  padding: 0.4rem;
  font-size: 2rem;
  opacity: 0;
}

.layout-row-add:hover {
  opacity: 1;
}

.layout-field .ant-btn {
  margin: 0;
  border-radius: 4px;
}

.layout-field {
  background-color: $catalog-widget-color-2;
  border: 1px solid $catalog-widget-text-color;
  color: $catalog-widget-text-color;
  font-size: 0.7rem;
  min-height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layout-field .layout-handle {
  padding: 0.4rem;
}

.layout-modal .ant-row {
  margin-bottom: 1rem;
}
