.catalog-sidebar-menu {
  background-color: $catalog-sidebar-background-color;
  // width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    padding: 0px;
  }

  label {
    margin-bottom: 4px;
  }
}

.catalog-sidebar-container {
  width: 304px;
  color: $catalog-sidebar-text-color;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  row-gap: 10px;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
}

.catalog-sidebar-widget {
  color: $catalog-widget-text-color;
  min-height: 60vh;
  width: 100%;
  height: 100%;
  background: #e6dad8;
  min-width: 90vw;
}

.catalog-sidebar-dropdown-select {
  width: 100%;
  font-family: 'Gotham book';
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  background: #f8f9fd;
  border-radius: 4px;
  min-height: 45px;
  .ant-select-selector {
    border: 0 !important;
    padding: 8px 16px !important;
    min-height: 45px;
  }
  span.ant-select-selection-item {
    color: rgb(51, 51, 51) !important;
    font-size: 14px !important;
  }
}

.catalog-sidebar-dropdown-label {
  font-family: 'NewKansas';
  font-style: normal;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  color: #e6bfb1;
}

.catalog-section {
  margin: 0;
}

.card-catalog {
  width: 100%;
  background: white;
  border: 0px;
  border-color: #009432;
  border-radius: 4px;
  aspect-ratio: 1.5;
  overflow: hidden;
}
.card-belongsto-cart {
  border: 2px solid !important;
  border-color: #009432 !important;
}

.belongs-to-card {
  position: absolute;
  margin-right: 1px;
}

.card-title {
  font-size: 15px;
  background: #f7f8f8;
  height: 40px;
  color: #5a6069;
  width: 100%;
  margin: 0px;
  margin-top: -6px;
  color: #5a6069;
  font-weight: bold;
}

.card-body-catalog {
  height: calc(100% - 34px);
  padding: 0 !important;
  width: 100%;
}

.catalog-card-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  height: 100% !important;
}

.article-text-ar {
  text-align: right !important;
}

.catalog-widget {
  color: $catalog-widget-text-color;
  min-height: 60vh;
  width: 100%;
  height: 100%;
  background: $catalog-widget-color-1;
  min-width: 90vw;
}

.catalog-content {
  background: #e6dad8;
  padding: 20px;
  margin-bottom: 1rem;
}

.catalog-sidebar-back {
  position: absolute;
  top: 30px;
  font-weight: 350;
  color: #ffffff;
  font-family: 'Gotham Book';
  width: 256px;
  font-size: 14px;
}

.catalog-sidebar-hide-menu {
  position: absolute;
  bottom: 30px;
  font-weight: 350;
  color: #ffffff;
  font-family: 'Gotham Book';
  width: 256px;
  font-size: 14px;
}

.catalog-sidebar-hide-menu-label {
  position: absolute;
  top: 52%;
  left: 37%;
  transform: translate(-50%, -50%);
}

.catalog-sidebar-hide-icon {
  float: left;
  margin-right: 0px;
  font-size: 20px;
  font-weight: 350;
  bottom: 0px;
}

.catalog-sidebar-show-icon {
  position: absolute;
  bottom: 0px;
  font-weight: bold;
  color: #ffffff;
  font-family: 'Gotham Book';
  font-size: 20px;
}

.catalog-sidebar-hide-container {
  width: 72px;
  color: $catalog-widget-text-color;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: NewKansas;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  @media (max-width: 600px) {
    fontsize: '1rem';
  }
}

.catalog-panel {
  padding: 1rem;
  color: $body-color;
  background-color: #111518;
  position: fixed;
  margin: auto;
  max-height: 100vh;
  width: -webkit-fill-available;
  overflow: scroll;
  z-index: 100;
  box-shadow:
    0 0 0px 1px #292c2c,
    0 0px 6px 4px rgba(0, 0, 0, 0.6);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.fullscreen {
    width: 100% !important;
    height: 100% !important;
  }

  &.rpanel {
    max-height: 100vh;
    max-width: 800px;
    width: 90%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: unset;
    transform: none;
  }
}

@media (min-width: 641px) {
  .catalog-panel {
    width: auto;
    max-height: 90vh;
    max-width: 90vw;
    border-radius: 4px;
    &.fullscreen {
      max-height: 100vh;
      max-width: 100vw;
      border-radius: 0px;
    }

    &.rpanel {
      max-height: 100vh;
    }
  }
}

.article-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 4px;
  width: auto;
  height: 100%;
}

.article-details-unavailable {
  background-color: #ffffff;
  border-radius: 4px;
  width: auto;
  height: auto;
}

.back-to-catalog-navigation {
  margin-right: 0.5rem;
  height: 15px !important;
}

.back-to-catalog-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #5a6069;
}

.btn-add-article {
  font-weight: bold;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  gap: 8px;
  width: 280px;
  height: 44px;
  background-color: #935a55;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  margin-top: 0.5rem;
}

.btn-remove-article {
  font-weight: bold;
  font-size: 14px;
  width: 156px;
  height: 44px;
  background: #119c40 !important;
  border-radius: 4px;
  color: #ffffff !important;
  border: none;
  margin-top: 0.5rem;
  cursor: auto !important;
}

.article-name {
  width: 440px;
  height: 54px;
  left: 352px;
  top: 96px;
  font-family: 'NewKansas';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 54px;
  color: #5a6069;
  margin-bottom: 50px;
}

.article-naviguation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 343px;
  height: 54px;
  left: 352px !important;
  top: 96px;
}

.article-navigation-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.article-navigation-text {
  font-weight: bold;
  font-size: 12px;
  margin-top: 32px;
  color: #5a6069;
}

.article-navigation-previous {
  color: #5a6069 !important;
  margin-right: 15px;
  height: 25px !important;
}

.article-navigation-next {
  color: #5a6069 !important;
  margin-left: 15px;
  height: 23px !important;
}

.article-image-gallery {
  width: 617px;
  height: 318px;
  left: 432px;
  top: 174px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.article-description {
  left: 352px;
  top: 608px;
  margin-top: 30px;
  line-height: 18px;
  color: #5a6069;
}

.article-price-information {
  margin-top: auto;
  padding-bottom: 20px;
  color: #935a55;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
}

.spaceAddArticle {
  margin-left: 1rem;
}

.catalog-searchbar-container {
  width: 100%;
}

.catalog-searchbar-label {
  font-family: NewKansas !important;
  font-style: normal;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  color: #e6bfb1;
}

.catalog-searchbar-input {
  margin: 0px;
  color: #212529 !important;
  border: 0px !important;
  border-radius: 4px !important;
  background: #f8f9fd;
  font-weight: 500;
}

.catalog-searchbar-input input {
  padding: 9px 1px !important;
  font-size: 14px !important;
  margin: 0px;
  color: #212529 !important;
  border: 0px !important;
  border-radius: 4px !important;
  background: #f8f9fd;
  font-weight: 500;
}

.form-check.form-switch {
  padding: 0px;
  width: 100%;
  line-height: 18px;
  font-size: 14px;
}

.form-check-label {
  font-style: normal;
  font-weight: 350;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  color: #ffff;
}

#catalog-toggle-switch {
  margin: 0px;
  float: right !important;
  background-color: #935a55;
  border-color: #ffff;
  margin-right: 7rem;
  margin-top: 2px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input:checked {
  background-color: rgba(0, 0, 0, 0);
  border-color: #ffff;
}

.form-switch .form-check-input:focus {
  border-color: #ffff;
  box-shadow: unset;
}

.catalog-sidebar-dropdown-icon {
  color: #5a6069;
}
