.gallery-controller {
  position: fixed;
  top: calc(50% - 16px);
  z-index: 6000;
  padding: 10px;
}

.gallery-controller.gallery-next-zoomed {
  right: 0;
  display: block;
}

.gallery-controller.gallery-next-none {
  right: 0;
  display: block;
}

.gallery-controller.gallery-next img {
  transform: rotateY(180deg);
}

.gallery-controller.gallery-prev-zoomed {
  left: 0;
  display: block;
}

.gallery-controller.gallery-prev-none {
  left: 0;
  display: none;
}

.gallery-thumbnail {
  max-width: 45px;
  max-height: 100px;
  margin: 5px;
  border-radius: 4px;
  border: 1px solid #c7c7c7;
}

.gallery-thumbnail:hover {
  border: 1px solid #9e9e9e;
}

.gallery-thumbnail.selected {
  border: 1px solid #32735f;
}

.gallery {
  flex-direction: column !important;
  max-height: 500px;
}

.gallery-thumbnails {
  flex-direction: row !important;
  justify-content: center !important;
  margin-top: 10px;
  margin-left: 0px;
}

.gallery-main {
  max-height: 300px;
}
