@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;600&display=swap');
@import url('https://fonts.cdnfonts.com/css/gotham');

@font-face {
  font-family: 'NewKansas';
  src: url('https://res.cloudinary.com/inmemori/raw/upload/CRM/fonts/new-kansas/NewKansas-Regular.eot'); /* IE9 Compat Modes */
  src:
    url('https://res.cloudinary.com/inmemori/raw/upload/CRM/fonts/new-kansas/NewKansas-Regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://res.cloudinary.com/inmemori/raw/upload/CRM/fonts/new-kansas/NewKansas-Regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
