$navbar-color: #606266;
$navbar-background-color: #fff;
$navbar-tag-color: #303133;

$code-editor-border-color: #ebeef5;
$code-editor-status-bar-height: 32px;
$code-editor-status-bar-background-color: white;
$code-editor-status-bar-text-color: #bdc3c7;
$code-editor-status-bar-text-color-error: #e74c3c;
$code-editor-status-bar-text-color-warning: #f39c12;

$catalog-sidebar-background-color: #935a55;
$catalog-sidebar-text-color: #e1e2e3;

$catalog-widget-color-1: #b8b9af;
$catalog-widget-color-2: #c4c4c4;
$catalog-widget-color-3: #74786f;
$catalog-widget-text-color: #101010;
$light: #cdcdcd;
$body-color: $light;
