/* Workflow */

.node {
  text-align: center;
  padding: 5px;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
  font-size: 8px;
  position: relative;
}

.node-selected {
  border: 2px solid #adadad;
}

.node-type {
  font-size: 6px;
  color: #9d9d9d;
  font-weight: bold;
}

.node-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.node-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 6px;
}

.react-flow__attribution {
  display: none !important;
}

.react-flow__node {
  cursor: default !important;
}

.drop-node-menu > .ant-menu-root {
  border-inline: none !important;
}

.default-node {
  padding-left: 9px;
}

.default-node::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: var(--node-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.node-selected.default-node::before {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
