.tm-collapse-icon {
  margin-left: 1rem;
  font-size: 12px;
  padding-top: 0.15rem;
  display: flex;
  justify-content: flex-end;
}

.tm-task-checkbox {
  margin-right: 0.5rem;
  margin-top: 0.2rem;
}

.tm-task-item-container {
  margin-left: 4rem;
  width: 90%;
}

.tm-link-checkbox {
  display: flex;
  justify-content: center;
}

.tm-task-team-bullet-avatar {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-size: 9px;
  color: #ffff;
  font-weight: bold;
  text-align: center;
  float: right;
  margin-right: 0.5rem;
}
.tm-task-flex {
  flex-grow: 1;
  flex-basis: auto;
}

.tm-task-flex-fit {
  flex: none;
}

.tm-task-owner-profile {
  margin-left: 0.5em;
}

.tm-task-done {
  text-decoration: line-through;
}

.tm-task-done-at-by {
  font-size: 14px;
  font-style: italic;
  color: rgba(96, 98, 102, 0.45);
  margin-left: 0.75rem;
  white-space: nowrap;
}

.tm-task-comment {
  font-size: 14px;
  font-style: italic;
  white-space: pre-line;
}

.tm-row {
  display: flex;
  flex-direction: row;
}

.tm-col {
  display: flex;
  flex-direction: column;
}

.tm-align-items-center {
  align-items: center;
}

.tm-progress-state {
  display: flex;
  justify-content: flex-end;
}

.tm-progress-progress {
  display: flex;
  justify-content: center;
}

.tm-section-card > .ant-card-head {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.tm-section-card > .ant-card-body {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.tm-collapse-panel > .ant-collapse-header {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.tm-task-name {
  font-weight: 500;
  white-space: pre-line;
}

.tm-taks-cta-button {
  border: 1px solid;
  border-color: #e6e6e6;
  border-radius: 5px !important;
  gap: 10px;
  width: 22px !important;
  min-width: 22px !important;
  height: 25px !important;
  float: right !important;
}

.tm-task-item-tag {
  border-radius: 12px;
  border: 0px;
  background-color: #e6e6e6;
  margin-top: 0.2rem;
  margin-right: 0.5rem !important;
  float: right;
}

.tm-empty-timeline-item {
  max-height: 0px !important;
  padding: 0px !important;
}

.tm-timeline-card {
  max-height: 72vh !important;
  overflow: scroll !important;
}

.tm-tasks-card {
  overflow: scroll !important;
  height: 72vh !important;
  .ant-card-body {
    padding: 10px;
  }
}
