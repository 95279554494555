.code-editor {
  &-container {
    .status-bar {
      border-top: 1px solid $code-editor-border-color;

      height: $code-editor-status-bar-height;

      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 6px;
      font-size: 12px;

      padding-right: 12px;
      padding-left: 12px;

      background-color: $code-editor-status-bar-background-color;
      color: $code-editor-status-bar-text-color;

      &-group {
        display: flex;
        align-items: center;
        gap: 3px;
      }

      &-item {
        transition: all 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }

        &.active {
          opacity: 0.6;
        }
      }

      .code-status {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 1;
        gap: 3px;

        &.error {
          color: $code-editor-status-bar-text-color-error;
          transform: scale(1.1);
        }

        &.warning {
          color: $code-editor-status-bar-text-color-warning;
          transform: scale(1.1);
        }
      }

      .fullscreen-btn {
        &:hover {
          transform: scale(1.2);
        }
      }
    }

    &.is-fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      z-index: 999999;

      height: 100vh;
      width: 100vw;
    }
  }
}
